<script setup>

import { useGlobalStore } from '@/store/global'

const props = defineProps(['textItem', 'verseIsActive'])

const store = useGlobalStore()
const settings = computed(() => store.getSettings)

const verseLinkNumberClassObject = computed(() => ({
   'font-bold relative z-10': true, 
   'text-blue-700': props.verseIsActive(props.textItem.id),
   'text-slate-800': !props.verseIsActive(props.textItem.id),
   'group-hover:text-blue-700': settings.value.highlightVerse
}))

</script>
<template>
    <span :class="verseLinkNumberClassObject">
        <sup class="-z-10">
            {{ textItem.verse }}{{ getVerseLetter(textItem.letter) }}
        </sup>
    </span>
</template>