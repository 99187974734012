<script setup>

import { useGlobalStore } from '@/store/global'
import { prepareText } from '@/utils/prepareText'
import { getVerseLetter } from '@/utils/getVerseLetter'

defineProps(['text', 'textKey'])

const store = useGlobalStore()
const settings = computed(() => store.getSettings)

const textItemStyleObject = computed(() => ({
    fontSize: settings.value.fontSize + 'px',
    lineHeight: '160%'
}))

const textItemClassObject = computed(() => ({
    block: settings.value.showVersePerLine
}))

</script>
<template>
    <div>

        <!-- Číslo kapitoly - napr. "1. kapitola" -->
        <ChapterTitle :textKey="textKey"/>

        <span 
            v-for="textItem in text" :key="text.id" :style="textItemStyleObject" :class="textItemClassObject">
            
            <!-- Nadpisy pred daným veršom, môže ich byť viac -->
            <VerseTitles :textItem="textItem"/>

            <!-- Verse container -->
            <VerseContainer :textItem="textItem" :textKey="textKey" /> <span class="block relative py-4 px-4 border shadow-md my-2" v-if="store.getActiveVerses.includes(textItem.id)">

                <div class="text-gray-400" v-if="store.getLoadingParallels.status && textItem.id == store.getLoadingParallels.textItemId">
                    <svg class="animate-spin mr-2 mb-1 h-5 w-5 text-blue-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Načítavam...
                </div>

                <span v-else v-for="(parallel, index) in textItem.parallels" :key="index">
                    <span class="block mb-2" v-if="store.getSettings.preferredCompareTranslations.includes(parallel.translation) && parallel.translation != textItem.translation">  
                    <span class="block">
                        <span class="font-bold">{{ parallel.translation_object.name }}</span>
                        <NuxtLink class="ml-2 text-blue-700" :to="{name: 'citanie', params: { translation: parallel.translation, book: parallel.book, chapter: parallel.chapter }}">
                            {{ parallel.book_object.abb_by_translation }}
                            {{ parallel.chapter }},{{ parallel.verse }}
                        </NuxtLink>
                    </span>
                    <span :class="['', parallel.translation == 'sbl' ? 'font-greek' : '', parallel.translation == 'csl' ? 'font-cyrilic' : '']" v-html="prepareText(parallel.search_text)"></span>
                    </span>
                </span>
                <span class="text-center block text-gray-400 mt-4" v-if="store.getSettings.preferredCompareTranslations.filter(value => value !== store.getSelectedTranslation.identifier).length === 0">Na porovnanie musíte <span class="underline text-blue-700 cursor-pointer" @click="toggleSidebar('readSettings')">v nastaveniach</span> zvoliť aspoň jeden iný preferovaný preklad ako ten, ktorý práve čítate.</span>
                <span @click="toggleSidebar('error'); store.handleErrorVerse(textItem.verse + '' + getVerseLetter(textItem.letter))" class="text-xs text-right text-gray-400 hover:text-blue-600 cursor-pointer absolute right-2 top-1">Nahlásenie chyby verša</span>
            </span>

        </span>

    </div>
</template>