import { useGlobalStore } from "@/store/global"

export const handleNonExistingBookAndChapter = (translation: any) => {
    const store = useGlobalStore()
    const selectedBook = computed(() => store.getSelectedBook)
    const selectedChapter = computed(() => store.getSelectedChapter)

    const hasSelectedBook = Boolean(
        translation.books.filter((book: any) => {
            // @ts-ignore
            return book.abbreviation == selectedBook.value.abbreviation // TODO: Property 'abbreviation' does not exist on type 'boolean'.ts(2339)
        }).length
    )

    const toBook = translation.books.filter((book: any) => {
        return book.testament == "new"
    })[0]

    const book = !hasSelectedBook ? toBook : selectedBook.value
    const chapter = book.chapters.includes(Number(selectedChapter.value))
        ? selectedChapter.value
        : book.chapters[0]

    return book.abbreviation + "/" + chapter
}
